

import * as search from "@/constants/search"
import * as rpc from "@/constants/rpc"

export default {
    name: "SearchBar",
    props: {
        input: String,
        usage: {
            type: String,
            default: "search"
        }
    },
    data() {
        return {
            newInput: '',
            select: '1',
            myKeyWords: [],
            hotKeyWords: []
        }
    },
    computed: {
        keyWords() {
            // 合并 我的搜索+系统热搜词
            let keywords = [...this.hotKeyWords, ...this.myKeyWords]
            // 去重
            keywords = Array.from(new Set(keywords))
            // 限制长度
            if (keywords.length > search.SHOW_COUNT) {
                keywords = keywords.slice(keywords.length - search.SHOW_COUNT)
            }
            return keywords.reverse()
        },
    },
    created() {
        this.newInput = this.$route.query?.q ?? ""
    },
    mounted() {
        this.getMyHotKeyWords()
        this.getHotKeyWords()
    },
    methods: {
        getMyHotKeyWords() {
            this.myKeyWords = this.$storage.array(search.MY_HOT_KEY_WORDS)
        },
        async getHotKeyWords() {
            //个人的搜索词大于最大显示数量，就不用加载热搜词了
            if (this.myKeyWords.length > search.SHOW_COUNT) {
                return []
            }
            const ret = await this.$axios.rpc(rpc.SEARCH, rpc.SEARCH_HOT)
            if (ret && ret.result && ret.result.hotWords) {
                this.hotKeyWords = ret.result.hotWords
            }
        },
        onSearch(content) {
            // if (!content) {
            //     return
            // }
            const str = content.trim()
            if (!this.myKeyWords.includes(str)) {
                this.myKeyWords.push(str)
                this.$storage.arrayAdd(search.MY_HOT_KEY_WORDS,str,search.SHOW_COUNT)
            }
            if (this.usage === "search") {
                this.newInput = str
                this.$emit("onApplySearch",str)
            } else {
                this.$router.push("/search?q=" + str)
            }

        }
    },

}
